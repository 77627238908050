import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Container from 'components/Container'

import { useTranslation } from 'gatsby-plugin-react-i18next'
// import image from 'images/shop-management.png'
// import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import Footer from "components/Footer"
import Header from "components/Header"

const VIDEO = 'desktop-easy-login'
const image = require(`images/${ VIDEO }.png`)


export default function Home() {

  const { t } = useTranslation()

  // const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={ t('Effortless sign in') } 
        subtitle={ t('Sign in with just a pin code from any of your linked devices') }
        dark 
      />
      <Constrain>
        <Video
            poster={ image }
            src={`https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/${ VIDEO }.mp4`}
        />

      </Constrain>

          {/* <div className='deviceIcons'>
            <Constrain>

            <img src={ windows } alt='Windows' />
            <img src={ apple } alt='Apple / iOS' />
            <img src={ android } alt='Android' />

          </Constrain>
        </div> */}

      <Container>

        <h1>{ t("Effortless sign in") }</h1>

        <p>
          { t("Sign in with just a pin code from any of your linked devices.") }
        </p>

        <p>
          { t("Link a device as an Administrator, Service Advisor or Technician device to allow team members to login from that device.") }
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
